import React from 'react';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import VideoModal from '../../components/ModalVideo'
import './style.css'
import blog1 from '../../images/blog/bangkok.jpeg'
import blog2 from '../../images/blog/chiangmai.jpeg'
import blog3 from '../../images/blog/waterfall.jpeg'


const BlogList = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


    return(
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={blog1} alt="" />
                                </div>
                                <ul className="entry-meta">
                                    <li><Link onClick={ClickHandler} to="/blog-details"> By admin</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"> 13 Nov 2024</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/blog-details">Prepare for the Unpredictable Adventure</Link></h3>
                                <p>Thailand, a land of vibrant culture, breathtaking landscapes, and boundless adventure, beckons travelers who crave the thrill of the unknown. Whether you’re a seasoned backpacker or a first-time visitor, Thailand has a way of surprising even the most prepared.</p>
                                <div className="entry-bottom">
                                    <Link onClick={ClickHandler} to="/blog-details" className="read-more">View More...</Link>
                                    <ul>
                                        <li><i className="fi flaticon-heart"></i>1</li>
                                        <li><i className="fi flaticon-share"></i></li>
                                    </ul>
                                </div>
                                <div className="blog-thumb-badge">
                                    <div className="blog-thumb-text">
                                        <span>Travelling</span>
                                    </div>
                                </div>
                            </div>

                            <div className="post format-gallery">
                                <div className="entry-media post-slider">
                                    <img src={blog2} alt="" />
                                </div>
                                <ul className="entry-meta">
                                    <li><Link onClick={ClickHandler} to="/blog-details"> By Admin</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"> 21 Oct 2024</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/blog-details">Best Places to Visit in the Thailand</Link></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.</p>
                                <div className="entry-bottom">
                                    <Link onClick={ClickHandler} to="/blog-details" className="read-more">View More...</Link>
                                    <ul>
                                        <li><i className="fi flaticon-heart"></i>1</li>
                                        <li><i className="fi flaticon-share"></i></li>
                                    </ul>
                                </div>
                                <div className="blog-thumb-badge">
                                    <div className="blog-thumb-text">
                                        <span>Travelling</span>
                                    </div>
                                </div>
                            </div>

                            <div className="post format-video">
                                <div className="entry-media video-holder">
                                    <img src={blog3} alt="" />
                                    <VideoModal/>
                                </div>
                                <div className="blog-thumb-badge">
                                    <div className="blog-thumb-text">
                                        <span>Travelling</span>
                                    </div>
                                </div>
                                <ul className="entry-meta">
                                    <li><Link onClick={ClickHandler} to="/blog-details"> By Admin</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"> 14 Nov 2024</Link></li>
                                </ul>
                                <h3><Link onClick={ClickHandler} to="/blog-details">How to Avoid Massive Crowds in Thailand</Link></h3>
                                <p>If you are willing to stay out of the main tourist locations, there are plenty of serene places to visit.</p>
                                <div className="entry-bottom">
                                    <Link onClick={ClickHandler} to="/blog-details" className="read-more">View More...</Link>
                                    <ul>
                                        <li><i className="fi flaticon-heart"></i>1</li>
                                        <li><i className="fi flaticon-share"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="pagination-wrapper pagination-wrapper-left"> 
                                <ul className="pg-pagination">
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog" aria-label="Previous">
                                            <i className="fi ti-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="active"><Link onClick={ClickHandler} to="/blog">1</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog">1</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog">1</Link></li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog" aria-label="Next">
                                            <i className="fi ti-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft}/>
                </div>
            </div>
        </section>
     )
        
}

export default BlogList;
