import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLine,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

const Footer = (props) => {

    const ClickHandler = (e) => {
        window.open();
    }

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="wpo-footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="logo" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                                <ul>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/share/1Ays5gZHuZ/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faFacebookF} />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.tiktok.com/@ogtix.asia?_t=8rMB7Zwk0GH&_r=1"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faTiktok} />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/ogtix.asia/profilecard/?igsh=MTVkdHQzMXdrbXF3bw=="
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faInstagram}/>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://page.line.me/noy2348o?oat_content=url&openQrModal=true"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faLine}/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget market-widget wpo-service-link-widget">
                                    <div className="widget-title">
                                        <h3>Contact </h3>
                                    </div>
                                    <p>You Can Reach Us On Social Media</p>
                                    <div className="contact-ft">
                                        <ul>
                                            <li><i className="fi ti-location-pin"></i>101/794 Moo 6, Rassada, Phuket</li>
                                            <li><i className="fi ti-mobile"></i>02-269-6999</li>
                                            <li><i className="fi flaticon-email"></i>bissyness@ogtix.asia</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget link-widget">
                                    <div className="widget-title">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul>
                                        <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                        <li><Link onClick={ClickHandler} to="/destination">Our Offers</Link></li>
                                        <li><Link onClick={ClickHandler} to="/contact">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-6 col-md-6 col-12">
                            <div className="term">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/">Privacy Policy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">Terms & Condition</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">Cookies</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-6 col-md-6 col-12">
                            <p className="copyright">&copy; 2024 OG Tix. All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;