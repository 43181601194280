import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import Iframe from '../../components/Iframe'
//import SearchSection from '../../components/SearchSection'
import About from '../../components/about'
import Destination from '../../components/Destination'
import VideoSection from '../../components/videoSection'
import BlogSection from '../../components/BlogSection'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import vdimg from '../../images/banner.png'

import hero1 from '../../images/slider/background1.jpeg'
import hero2 from '../../images/slider/background2.jpeg'
import hero3 from '../../images/slider/background3.jpeg'


const HomePage =() => {
    return(
        <Fragment>
            <Navbar/>
            <Hero heroClass={'hero-style-1'} heroImg1={hero1} heroImg2={hero2} heroImg3={hero3}/>
            <Iframe/>
            <About/>
            <Destination/>
            <VideoSection vdImg={vdimg}/>
            <BlogSection/>
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;