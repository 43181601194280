import React from 'react'
import {Link} from 'react-router-dom'
import ab1 from '../../images/about/temple3.jpeg'
import './style.css'

const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={ab1} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                <span>About Us</span>
                                <h2>Best Deals <br/> For Your Tickets </h2>
                            </div>
                            <p>With decades of experience exploring every facet of Thailand, we pride ourselves on our unparalleled expertise and dedication to crafting unforgettable travel experiences. Our extensive local knowledge and strong industry connections empower us to secure the best deals, ensuring you receive exceptional value without compromising on quality. Whether you're dreaming of pristine beaches, vibrant cities, or rich cultural landmarks, trust our seasoned team to guide you seamlessly through Thailand’s diverse landscapes, delivering personalized journeys that exceed your expectations.</p>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="/about" className="theme-btn-s2">More About Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;