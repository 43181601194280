import React from 'react';
import {Link} from 'react-router-dom'
import rb1 from '../../images/blog/bangkok.jpeg'
import rb2 from '../../images/blog/chiangmai.jpeg'
import rb3 from '../../images/blog/waterfall.jpeg'

import inst1 from '../../images/destination/krabi.jpeg'
import inst2 from '../../images/destination/phiphi.jpeg'
import inst3 from '../../images/destination/udonthani.jpeg'
import inst4 from '../../images/destination/ranong.jpeg'
import inst5 from '../../images/destination/surathani.jpeg'
import inst6 from '../../images/destination/sukhothai.jpeg'

import about from '../../images/blog/profile.png'
import bicon from '../../images/boats.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLine, faTiktok } from '@fortawesome/free-brands-svg-icons'


import './style.css'

const BlogSidebar = (props) => {


    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


    return(
        <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
            <div className="wpo-blog-sidebar">
                <div className="widget profile-widget">
                    <div className="profile-img">
                        <img src={about} alt="" />
                        <h2>Admin</h2>
                        <p>Hi! here is our blog. Read our post - and be with us</p>
                    </div>
                    <div className="pro-social">
                        <ul>
                        <li><a
                                            href="https://www.facebook.com/share/1Ays5gZHuZ/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faFacebookF} />
                                        </a></li>
                                        <li><a
                                            href="https://www.tiktok.com/@ogtix.asia?_t=8rMB7Zwk0GH&_r=1"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faTiktok} />
                                        </a>
                                        </li>
                                        <li>
                                        <a
                                            href="https://www.instagram.com/ogtix.asia/profilecard/?igsh=MTVkdHQzMXdrbXF3bw=="
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faInstagram}/>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://page.line.me/noy2348o?oat_content=url&openQrModal=true"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faLine}/>
                                        </a>
                                    </li>
                        </ul>
                    </div>
                </div>
                
                <div className="widget category-widget">
                    <h3>Categories</h3>
                    <ul>
                        <li><Link onClick={ClickHandler} to="/blog-details">Vacational Plan<span>(0)</span></Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details">Thailand Tour <span>(0)</span></Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details">Guide Information<span>(0)</span></Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details">Travelling <span>(3)</span></Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details">Hotels in Destinations <span>(0)</span></Link></li>
                    </ul>
                </div>
                <div className="widget recent-post-widget">
                    <h3>Recent posts</h3>
                    <div className="posts">
                        <div className="post">
                            <div className="img-holder">
                                <img src={rb1} alt="" />
                            </div>
                            <div className="details">
                                <h4><Link onClick={ClickHandler} to="/blog-details">Prepare for the Unpredictable Adventure</Link></h4>
                                <span className="date">22 Sep 2020</span>
                            </div>
                        </div>
                        <div className="post">
                            <div className="img-holder">
                                <img src={rb2} alt="" />
                            </div>
                            <div className="details">
                                <h4><Link onClick={ClickHandler} to="/blog-details-left">Best Places to Visit in the Thailand</Link></h4>
                                <span className="date">22 Sep 2020</span>
                            </div>
                        </div>
                        <div className="post">
                            <div className="img-holder">
                                <img src={rb3} alt="" />
                            </div>
                            <div className="details">
                                <h4><Link onClick={ClickHandler} to="/blog-details-left">How to Avoid Massive Crowds in Thailand</Link></h4>
                                <span className="date">22 Sep 2020</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget instagram">
                    <h3>Destinations</h3>
                    <ul className="d-flex">
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst1} alt="" /></Link></li>
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst2} alt=""/></Link></li>
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst3} alt=""/></Link></li>
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst4} alt=""/></Link></li>
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst5} alt=""/></Link></li>
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst6} alt=""/></Link></li>
                    </ul>
                </div>
                <div className="widget tag-widget">
                    <h3>Tags</h3>
                    <ul>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Travelling</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Hotel</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Restaurant</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Destination</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Thailand Tour</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Hotel Room</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Spa</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Guide</Link></li>
                    </ul>
                </div>
                <div className="widget get-intouch">
                    <div className="get-item">
                        <img src={bicon} alt=""/>
                        <h2>Let’s start your dreamy journey</h2>
                        <Link onClick={ClickHandler} className="theme-btn" to="/contact">Get in touch</Link>
                    </div>
                </div>
            </div>
        </div>
     )
        
}

export default BlogSidebar;
