import React from 'react'
import {Link} from 'react-router-dom'
import dsn1 from '../../images/destination/bangkok.jpeg'
import dsn2 from '../../images/destination/chiangmai.jpeg'
import dsn3 from '../../images/destination/phiphi.jpeg'
import dsn4 from '../../images/destination/sukhothai.jpeg'

import './style.css'

const Destination = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="destination-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="destination-text">
                            <div className="wpo-section-title">
                                <span>Popular Destinations </span>
                                <h2>Choose Your Favorite</h2>
                            </div>
                            <p>Thailand is a vibrant tapestry of diverse tourist destinations, each offering its own unique charm and experiences. Bangkok, the bustling capital, captivates visitors with its ornate temples, lively street markets, and vibrant nightlife, blending modern skyscrapers with traditional Thai culture. In contrast, Chiang Mai in the north is renowned for its serene mountainous landscapes, ancient temples, and rich cultural heritage, making it a haven for those seeking tranquility and spiritual enrichment. The southern islands, such as Phuket and Koh Samui, boast stunning white-sand beaches, crystal-clear waters, and world-class resorts, perfect for sunbathing, water sports, and luxurious relaxation. Historical enthusiasts are drawn to Ayutthaya, where the ruins of ancient temples and palaces narrate Thailand’s rich past.</p>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="/about" className="theme-btn-s2">Discover More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="destination-wrap">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 custom-grid">
                                    <div className="destination-left">
                                        <div className="destination-item">
                                            <div className="destination-img">
                                                <img src={dsn1} alt="" />
                                            </div>
                                            <div className="destination-content">
                                                <div className="content-left">
                                                    <h5><Link onClick={ClickHandler} to="/room-single">Bangkok</Link></h5>
                                                    <small>Daily</small>
                                                </div>
                                                <div className="content-right">
                                                    <h5>350THB</h5>
                                                    <span>Starting</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="destination-item">
                                            <div className="destination-img">
                                                <img src={dsn2} alt="" />
                                            </div>
                                            <div className="destination-content">
                                                <div className="content-left">
                                                    <h5><Link onClick={ClickHandler} to="/room-single">Chiang Mai</Link></h5>
                                                    <small>Daily</small>
                                                </div>
                                                <div className="content-right">
                                                    <h5>350THB</h5>
                                                    <span>Starting</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 custom-grid">
                                    <div className="destination-right">
                                        <div className="destination-item">
                                            <div className="destination-img">
                                                <img src={dsn3} alt="" />
                                            </div>
                                            <div className="destination-content">
                                                <div className="content-left">
                                                    <h5><Link onClick={ClickHandler} to="/room-single">Phuket</Link></h5>
                                                    <small>Daily</small>
                                                </div>
                                                <div className="content-right">
                                                    <h5>500THB</h5>
                                                    <span>Starting</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="destination-item">
                                            <div className="destination-img">
                                                <img src={dsn4} alt="" />
                                            </div>
                                            <div className="destination-content">
                                                <div className="content-left">
                                                    <h5><Link onClick={ClickHandler} to="/room-single">Sukhothai</Link></h5>
                                                    <small>Daily</small>
                                                </div>
                                                <div className="content-right">
                                                    <h5>350THb</h5>
                                                    <span>Starting</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Destination;