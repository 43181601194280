import React from 'react';
import './style.css';

const Iframe = (props) => {
    return (
        <div className={`wpo-select-section ${props.selectClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <iframe
                            title='OGTix Iframe'
                            id="searchform"
                            src="https://bus-tickets.busx.com/widget-v2?appkey=ddd7651cbb6a2be6465bbda66d048e61&locale=en_EN"
                            width="100%"
                            height="750"
                            frameBorder="0"
                            scrolling="no"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Iframe;
