import React from 'react';
import { Link } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import './style.css'
import blog1 from '../../images/blog/bangkok.jpeg'
import blog2 from '../../images/blog/profile.png'
import blog3 from '../../images/blog-details/comments-author/img-1.jpg'
import blog4 from '../../images/blog-details/comments-author/img-2.jpg'
import blog5 from '../../images/blog-details/comments-author/img-3.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faFacebookF, faInstagram, faLine, faTiktok } from '@fortawesome/free-brands-svg-icons';


const BlogSingle = (props) => {
    const submitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return (
        <section className="wpo-blog-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content clearfix">
                            <div className="post">
                                <div className="entry-media">
                                    <img src={blog1} alt="" />
                                </div>
                                <ul className="entry-meta">
                                    <li><Link onClick={ClickHandler} to="/blog-details"> By Admin</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-details"> 13 Nov 2024</Link></li>
                                </ul>
                                <h2>Prepare for the Unpredictable Adventure</h2>
                                <p>Thailand, a land of vibrant culture, breathtaking landscapes, and boundless adventure, beckons travelers who crave the thrill of the unknown. Whether you’re a seasoned backpacker or a first-time visitor, Thailand has a way of surprising even the most prepared. Here’s how to embrace the unpredictable and make the most of your Thai adventure!</p>
                                <p>Thailand offers a dynamic blend of serene temples, bustling cities, and idyllic islands. While it’s tempting to map out every stop, leave room for the unexpected. You might stumble upon a local festival in Chiang Mai, a hidden café in Bangkok’s backstreets, or a secluded beach on Koh Lanta. The best experiences often come when you’re open to going off-script.</p>
                                <blockquote>The tropical climate can bring both sunshine and sudden downpours, so pack light yet versatile clothing, a sturdy rain jacket, and good walking shoes. A small waterproof bag is also handy for protecting your essentials during a spontaneous boat trip or jungle trek.</blockquote>
                                <p>Thailand’s warm and welcoming locals are often eager to share their favorite spots and stories. Learn a few Thai phrases like “Sawasdee” (hello) and “Khop khun” (thank you) – it can go a long way in connecting and even receiving insider tips on places tourists rarely see.</p>
                                <p>While the Grand Palace and Phi Phi Islands are iconic, Thailand has so much more to offer. Head north to the misty mountains of Chiang Rai, dive into the history of Ayutthaya, or experience the rustic charm of rural Isaan. Each region reveals a different side of Thailand that will deepen your connection to this incredible country.</p>
                                <p>From fiery street food in Bangkok to fresh coconuts on the islands, Thailand is a paradise for food lovers. Don't be afraid to try something new – whether it's a spicy som tam (papaya salad) from a roadside vendor or freshly grilled seafood on a beach. Thai cuisine is as diverse and unexpected as the country itself, offering tastes that will linger in your memory long after your trip.</p>
                                <p>While adventure is at the heart of travel, remember to stay mindful. Respect local customs, and always have a plan for staying connected, especially when venturing into rural areas. Travel insurance is highly recommended; Thailand is safe, but being prepared ensures peace of mind in those unpredictable moments.</p>
                                <p>Thailand’s blend of ancient tradition and modern vibrancy creates a backdrop for adventures that will surprise and inspire you. So pack your sense of wonder, leave room for spontaneity, and get ready to experience the unforgettable – because in Thailand, every corner turned holds a new discovery. Embrace the unpredictable, and let Thailand show you its magic in ways you never expected.</p>
                            </div>

                            <div className="tag-share clearfix">
                                <div className="tag">
                                    <ul>
                                        <li><Link onClick={ClickHandler} to="/blog-details">Business</Link></li>
                                        <li><Link onClick={ClickHandler} to="/blog-details">Marketing</Link></li>
                                    </ul>
                                </div>
                                <div className="share">
                                    <ul>
                                        <li><a
                                            href="https://www.facebook.com/share/1Ays5gZHuZ/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faFacebookF} />
                                        </a></li>
                                        <li><a
                                            href="https://www.tiktok.com/@ogtix.asia?_t=8rMB7Zwk0GH&_r=1"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faTiktok} />
                                        </a>
                                        </li>
                                        <li>
                                        <a
                                            href="https://www.instagram.com/ogtix.asia/profilecard/?igsh=MTVkdHQzMXdrbXF3bw=="
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faInstagram}/>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://page.line.me/noy2348o?oat_content=url&openQrModal=true"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faLine}/>
                                        </a>
                                    </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="author-box">
                                <div className="author-avatar">
                                    <Link onClick={ClickHandler} to="/blog-details" target="_blank"><img src={blog2} alt="" /></Link>
                                </div>
                                <div className="author-content">
                                    <Link onClick={ClickHandler} to="/blog-details" className="author-name">Admin</Link>
                                    <p>Webmaster of the site and dedicated traveler of Thailand</p>
                                    <div className="author-btn">
                                        <Link onClick={ClickHandler} to="/blog-details">All Post From Author</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="more-posts clearfix">
                                <div className="previous-post">
                                    <Link onClick={ClickHandler} to="/blog-details">
                                        <span className="post-control-link">Previous</span>
                                    </Link>
                                </div>
                                <div className="next-post">
                                    <Link onClick={ClickHandler} to="/blog-details">
                                        <span className="post-control-link">Next post</span>
                                    </Link>
                                </div>
                            </div>

                            <div className="comments-area">
                                <div className="comments-section">
                                    <h3 className="comments-title">Comments</h3>
                                    <ol className="comments">
                                        <li className="comment even thread-even depth-1" id="comment-1">
                                            <div id="div-comment-1">
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt="" /></div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>Test <span className="comments-date">November 13,2024 At 9.00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>blaah </p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link"  to="/blog-details"><i className="fa fa-reply" aria-hidden="true"></i><span>Reply</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="children">
                                                <li className="comment">
                                                    <div>
                                                        <div className="comment-theme">
                                                            <div className="comment-image"><img src={blog4} alt="" /></div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                    <h4>test2 <span className="comments-date">November 13,2024 At 9.00am</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>blaah blaah blaah </p>
                                                                    <div className="comments-reply">
                                                                        <Link onClick={ClickHandler} to="/blog-details" className="comment-reply-link"><span><i className="fa fa-reply" aria-hidden="true"></i> Reply</span></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li className="comment">
                                                            <div>
                                                                <div className="comment-theme">
                                                                    <div className="comment-image"><img src={blog5} alt="" /></div>
                                                                </div>
                                                                <div className="comment-main-area">
                                                                    <div className="comment-wrapper">
                                                                        <div className="comments-meta">
                                                                            <h4>Test <span className="comments-date">November 13,2024 At 9.00am</span></h4>
                                                                        </div>
                                                                        <div className="comment-area">
                                                                            <p>blaah blaah</p>
                                                                            <div className="comments-reply">
                                                                                <Link onClick={ClickHandler} to="/blog-details" className="comment-reply-link"><span><i className="fa fa-reply" aria-hidden="true"></i> Reply</span></Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>

                                        
                                    </ol>
                                </div>
                                <div className="comment-respond">
                                    <h3 className="comment-reply-title">Leave a Comment</h3>
                                    <form onSubmit={submitHandler} method="post" id="commentform" className="comment-form">
                                        <div className="form-inputs">
                                            <input placeholder="Name" type="text" />
                                            <input placeholder="Email" type="email" />
                                            <input placeholder="Website" type="url" />
                                        </div>
                                        <div className="form-textarea">
                                            <textarea id="comment" placeholder="Write Your Comments..."></textarea>
                                        </div>
                                        <div className="form-submit">
                                            <input id="submit" value="Reply" type="submit" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft}/>
                </div>
            </div>
        </section>
    )

}

export default BlogSingle;
