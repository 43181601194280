
import React from "react";

const Pricing = (props) => {

    return(
        <div className="pricing-area">
            <div className="room-title">
                <h2>Pricing Plans</h2>
            </div>
            <div className="pricing-table">
                <table className="table-responsive pricing-wrap">
                    <thead>
                        <tr>
                            <th>Mon</th>
                            <th>Tue</th>
                            <th>Wed</th>
                            <th>Thu</th>
                            <th>Fri</th>
                            <th>Sat</th>
                            <th>Sun</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>$250</td>
                            <td>$250</td>
                            <td>$250</td>
                            <td>$250</td>
                            <td>$250</td>
                            <td>$250</td>
                            <td>$250</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>
    )
}

export default Pricing;